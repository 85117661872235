<template>
  <Transition name="modal">
    <div v-if="manageContactDetailsModalsState" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeManageContactModals"
          >
            ✖
          </div>
          <div class="w-10/12 mb-6">
            <h1 class="text-2xl">Manage Contact Details</h1>
            <p class="mt-2 font-proximaLight text-sm text-left">
              On the next screen, you can adjust your service details, such as
              24/7 availability or payment methods.
            </p>
          </div>
          <div class="w-10/12 flex">
            <div class="w-6/12 mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Phone Number*</label
              >
              <input
                type="text"
                v-model="sp.phoneNo"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
                :disabled="true"
              />
            </div>
            <div class="w-6/12 ml-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="emailAddress"
                >Email Address*</label
              >
              <input
                type="text"
                v-model="sp.emailAddress"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
                :disabled="true"
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-full mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Website(optional)</label
              >
              <input
                type="text"
                v-model="sp.website"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-full mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Street Address</label
              >
              <input
                type="text"
                v-model="sp.streetAddress"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-6/12 mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Postal Code*</label
              >
              <input
                type="text"
                v-model="sp.postalCode"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
              />
            </div>
            <div class="w-6/12 ml-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="emailAddress"
                >City*</label
              >
              <input
                type="text"
                v-model="sp.city"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-6/12 mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="emailAddress"
                >State*</label
              >
              <select
                class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm bg-lightGrey20"
                v-model="sp.state"
                @change="inputSelectState()"
              >
                <option value="sp.state" disabled selected>
                  {{ $t("global_select_options") }}
                </option>
                <option
                  v-for="item in statesList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="w-6/12 ml-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="emailAddress"
                >Country*</label
              >
              <select
                class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm bg-lightGrey20"
                v-model="sp.country"
                @change="inputSelectCountry()"
              >
                <option value="" disabled selected>
                  {{ $t("global_select_options") }}
                </option>
                <option
                  v-for="item in countriesList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="w-10/12 mt-6 flex">
            <span class="font-proximaLight text-sm text-red"
              >Discoverable in Directory</span
            >
            <RadioOnoffBorder
              :val="sp"
              :checked="sp.discoverable"
              @inputVal="inputVal"
            />
          </div>
          <div class="mt-10 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeManageContactModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                title="Continue"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="openManageServiceModals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapState } from "vuex";
import { getCountries } from "../../../services/countriesServices";
import { getStates } from "../../../services/stateServices";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    RadioOnoffBorder,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      phoneNumber: "",
      emailAddress: "",
      website: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      discoverable: false,
      countriesList: [],
      statesList: [],
    };
  },

  computed: {
    ...mapState(["manageContactDetailsModalsState"]),
    ...mapGetters({
      sp: "serviceprovider/getSP",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  async created() {
    this.getSP(localStorage.getItem("email"));
    const a = await getCountries();
    this.countriesList = a.data;

   /*  if (this.sp.country != "") {
      const c = await getStates(this.sp.country);
      this.statesList = c.data;
    } */
  },
  methods: {
    ...mapActions({
      getSP: "serviceprovider/getSP",
    }),
    async inputSelectCountry() {
     /*  if (this.account.country != "") {
        const a = await getStates(this.account.country);
        this.statesList = a.data;
      } */
    },
    closeManageContactModals() {
      this.$store.commit("manageContactDetailsModals", false);
    },
    openManageServiceModals() {
      this.$store.commit("manageServiceDetailsModals", true);
      this.$store.commit("manageContactDetailsModals", false);
      console.log(this.sp);
    },
    inputVal(i, e) {
      // console.log(i);
      console.log(e);
      this.sp.discoverable = e;
    },

    validate() {
      const errors = {};

      if (this.sp.phoneNo === "") errors.phoneNo = "Phone number is required";

      if (this.sp.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.sp.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.sp.streetAddress.trim() === "")
        errors.streetAddress = "Address  is required";
      if (this.sp.country === "") errors.country = "Country is required";
      if (this.sp.state === "") errors.state = "State  is required";
      if (this.sp.city.trim() === "") errors.city = "City  is required";
      if (this.sp.postalCode.trim() === "")
        errors.postalCode = this.$t("global_error_postal_code");
      if (this.sp.businessName.trim() === "")
        errors.businessName = this.$t("account_error_business_name");
      if (this.sp.taxID.trim() === "")
        errors.taxID = this.$t("account_error_business_tax_id");

      return Object.keys(errors).length === 0 ? null : errors;
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
