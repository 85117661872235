<template>
  <div class="my-6">
    <DashboardSection />
    <NavMenuSecondary page="sppassivedashboard" />
  </div>
</template>

<script>
import DashboardSection from "../components/sppassivedashboard/DashboardSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    DashboardSection,
    NavMenuSecondary
  },
};
</script>