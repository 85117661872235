<template>
  <Transition name="modal">
    <div v-if="ratingReminderModals" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <img
          src="@/assets/images/PeekVersionB.png"
          alt="PeekVersionB"
          class="absolute top-48"
          style="left: 50%; transform: translateX(-50%); z-index:2;"
        />
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeRatingReminderModals"
          >
            ✖
          </div>
          <div class="">
            <h1 class="mt-2 text-2xl">
              Don’t forget to rate [Pet name]’s visit!
            </h1>
            <p class="mt-4 text-sm font-proximaLight">
              You can leave a review of your experience within three days after
              an appointment has passed. Simply visit your dashboard and click
              on the pet’s name which will be highlighted in <span class="text-orange">orange</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    ratingReminderModals: Boolean,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    closeRatingReminderModals() {
      this.$emit("closeRatingReminderModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
