<template>
  <div class="mt-5">
    <div class="flex items-center">
      <div class="flex w-6/12">
        <RadioOnoffBorder
          :val="{ name: 'tuesday' }"
          :checked="false"
          @inputVal="tuesdayVal"
        />
        <span class="font-proximaMedium text-sm">Tuesday</span>
      </div>
      <div class="w-5/12 flex justify-end">
        <AddIconVue
          class="cursor-pointer"
          :width="24"
          :height="24"
          @click.native="addTime = true"
          v-if="!addTime"
        />
      </div>
    </div>
    <div v-if="addTime" class="flex justify-between items-center">
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Working Hours</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            v-model="tuesdayStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[tuesdayStart.HH, 23]]"
            v-model="tuesdayEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Break Time</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[tuesdayStart.HH, tuesdayEnd.HH]]"
            v-model="tuesdayBreakStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[tuesdayBreakStart.HH, tuesdayEnd.HH]]"
            v-model="tuesdayBreakEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <trash-icon-vue
        bgColor="#0AC0CB"
        iconColor="#fff"
        :width="24"
        :height="24"
        class="mt-9 cursor-pointer"
        @click.native="removeTime"
        v-if="addTime"
      />
    </div>
  </div>
</template>

<script>
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import TrashIconVue from "../../icons/TrashIcon.vue";
import AddIconVue from "../../icons/AddIcon.vue";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.umd.min.js";

import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: ["start", "end", "breakStart", "breakEnd", "addTimee"],
  components: {
    RadioOnoffBorder,
    AddIconVue,
    VueTimepicker,
    TrashIconVue,
  },
  data() {
    return {
      tuesdayStart: {
        HH: "",
        mm: "",
      },
      tuesdayEnd: {
        HH: "",
        mm: "",
      },
      tuesdayBreakStart: {
        HH: "",
        mm: "",
      },
      tuesdayBreakEnd: {
        HH: "",
        mm: "",
      },
      addTime: this.addTimee,
    };
  },
  watch: {
    breakEnd(i) {
      if (
        this.start.mm == 0 &&
        this.end.mm == 0 &&
        this.breakStart.mm == 0 &&
        i.mm == 0
      ) {
        this.addFromParentMethod();
      } else {
        this.removeFromParentMethod();
      }
    },
  },
  methods: {
    removeTime() {
      addTime = false;
      this.$emit("removeTime", "tuesday");
    },
    changeHandler(eventData) {
      const mergedArray = [].concat(
        this.tuesdayStart,
        this.tuesdayEnd,
        this.tuesdayBreakStart,
        this.tuesdayBreakEnd
      );
      this.$emit("tuesdayHours", mergedArray);
    },
    tuesdayVal(i, e) {
      console.log(i);
      console.log(e);
    },
    addFromParentMethod() {
      this.addTime = true;
      this.tuesdayStart = this.start;
      this.tuesdayEnd = this.end;
      this.tuesdayBreakStart = this.breakStart;
      this.tuesdayBreakEnd = this.breakEnd;
    },
    removeFromParentMethod() {
      this.addTime = false;
      this.tuesdayStart = {};
      this.tuesdayEnd = {};
      this.tuesdayBreakStart = {};
      this.tuesdayBreakEnd = {};
    },
  },
};
</script>

<style></style>
