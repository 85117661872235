<template>
  <Transition name="modal">
    <div v-if="ratingConfirmationModals" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeRatingConfirmationModals"
          >
            ✖
          </div>
          <div class="">
            <img
              src="@/assets/images/success-icon.png"
              alt="success-icon"
              class="m-auto h-24"
            />
            <h1 class="mt-4 text-2xl">Thanks for rating!</h1>
            <p class="font-proximaLight">
              Ratings help us ensure quality user experience and keep the
              community safe. To be fair, ratings and reviews will become public
              after at least three votes have been given!
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    ratingConfirmationModals: Boolean,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    closeRatingConfirmationModals() {
      this.$emit("closeRatingConfirmationModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
