<template>
  <Transition name="modal">
    <div v-if="manageServiceDetailsModalsState" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeManageContactModals"
          >
            ✖
          </div>
          <div class="w-10/12 mb-6">
            <h1 class="text-2xl">Manage Service Details</h1>
            <p class="mt-2 font-proximaLight text-sm">
              Please fill out the details for your service offerings below.
            </p>
          </div>
          <div class="w-10/12 flex">
            <div class="w-6/12 mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Business Name*</label
              >
              <input
                type="text"
                v-model="sp.businessName"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
              />
            </div>
            <div class="w-6/12 ml-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="emailAddress">{{
                $t("service_directoty_service_category") + "*"
              }}</label>

              <select
                class="w-full bg-white text-mediumGrey rounded-md px-2 py-3 my-1 text-sm bg-lightGrey20"
                v-model="sp.serviceType"
                @change="inputSelectCountry()"
              >
                <option value="" disabled selected>
                  {{ $t("service_directoty_select_category") }}
                </option>
                <option
                  v-for="item in serviceTypesList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-full mr-1 flex flex-col justify-start items-start">
              <label class="text-sm font-proximaMedium" for="phoneNumber"
                >Business Tax ID*</label
              >
              <input
                type="text"
                v-model="sp.taxID"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
                placeholder="Please enter your tax ID."
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-full mt-1 flex flex-col justify-start items-start">
              <label class="checkbox-container"
                >English / Multilingual
                <input
                  type="checkbox"
                  value="English / Multilingual"
                  name="avoid2"
                  v-model="sp.english_multilang"
                  @change="disableMultilingual = !disableMultilingual"
                />
                <span class="checkmark"></span>
              </label>
              <input
                type="text"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
                :class="{ 'cursor-not-allowed': disableMultilingual }"
                placeholder="English, German, Spanish... "
                :disabled="disableMultilingual"
              />
            </div>
          </div>
          <div class="w-10/12 flex">
            <div class="w-full mt-1 flex flex-col justify-start items-start">
              <label class="checkbox-container"
                >Special Equipment
                <input
                  type="checkbox"
                  value="Special Equipment"
                  name="avoid2"
                  v-model="sp.special_equipment"
                  @change="disableSpecialEquipment = !disableSpecialEquipment"
                />
                <span class="checkmark"></span>
              </label>
              <input
                type="text"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-lightGrey20"
                :class="{ 'cursor-not-allowed': disableSpecialEquipment }"
                placeholder="24-hour Holter, digital x-ray..."
                :disabled="disableSpecialEquipment"
              />
            </div>
          </div>
          <div class="mt-4 w-10/12 flex justify-between">
            <label class="checkbox-container w-6/12 text-left"
              >Loyalty program offered
              <input
                type="checkbox"
                value="Loyalty program offered"
                name="avoid2"
                v-model="sp.loyalty_program"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 text-left"
              >24/7 service available
              <input
                type="checkbox"
                value="24/7 service available"
                name="avoid2"
                v-model="sp.service_24_7"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="mt-4 w-10/12 flex justify-between">
            <label class="checkbox-container w-6/12 text-left"
              >Deferred payment accepted
              <input
                type="checkbox"
                value="Deferred payment accepted"
                name="avoid2"
                v-model="sp.deferred_payment"
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-container w-6/12 text-left"
              >Credit cards accepted
              <input
                type="checkbox"
                value="Credit cards accepted"
                name="avoid2"
                v-model="sp.accept_credit_card"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="mt-10 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeManageContactModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                title="Continue"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="openSetOpeningHourModals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import { getbusinessTypes } from "../../../services/serviceDirectoryService";

export default {
  components: {
    RadioOnoffBorder,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      disableSpecialEquipment: true,
      disableMultilingual: true,
      businessName: "",
      serviceCategory: "",
      businessTax: "",
      multilingual: "",
      specialEquipment: "",
      loyaltyProgram: false,
      serviceAvailable: false,
      deferredPayment: false,
      creditcardAccepted: false,
      serviceTypesList: [],
    };

    /*
accessible_entry
businessName
date_created

firstName
lastName

passive
profileImage
profile_business_street_address
selfEmployed

special_equipment
 */
  },
  computed: {
    ...mapState(["manageServiceDetailsModalsState"]),
    ...mapGetters({
      sp: "serviceprovider/getSP",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  async created() {
    this.getSP(localStorage.getItem("email"));

    await getbusinessTypes().then((result) => {
      if (this.sp.businessName) {
        this.serviceTypesList = this.serviceTypesList.concat(
          result.data.business_service_provider
        );
      } else {
        this.serviceTypesList = this.serviceTypesList.concat(
          result.data.business_vet
        );
      }
    });
  },
  methods: {
    ...mapActions({
      getSP: "serviceprovider/getSP",
    }),
    closeManageContactModals() {
      this.$store.commit("manageServiceDetailsModals", false);
    },
    openSetOpeningHourModals() {
      this.$store.commit("setOpeningHourModals", true);
      this.$store.commit("manageServiceDetailsModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  //   margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: 2px solid #0ac0cb;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  top: -1px;
  left: 3px;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>
