<template>
  <div
    class="w-10/12 bg-lightBlue rounded-lg p-10 flex flex-wrap font-proximaLight"
  >
    <div
      v-for="(data, i) in pets"
      :key="i"
      class="w-2/12 mt-2 mb-2 flex flex-col items-center justify-center relative"
    >
      <ListOfPetsMenu :pet="data" />
      <img
        :src="data.profile_picture"
        :alt="data"
        class="rounded-full w-24 h-24"
        @click="routeTo(data)"
      />
      <span class="mt-2 text-sm">{{ data.callName }}</span>
    </div>
  </div>
</template>

<script>
import ListOfPetsMenu from "./ListOfPetsMenu.vue";

export default {
  props: ["pets"],
  components: {
    ListOfPetsMenu,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    async routeTo(pet) {
      // Put the pet object into storage
      localStorage.setItem("pet", JSON.stringify(pet));
      console.log("pet : ", pet);

      this.$router.push({ path: "/publicprofile/serviceproviderview" });
    },
  },
};
</script>

<style></style>
