<template>
  <Transition name="modal">
    <div v-if="setOpeningHourModalsState" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeManageContactModals"
          >
            ✖
          </div>
          <div class="w-10/12 mb-6">
            <h1 class="text-2xl">Set Opening Hours</h1>
            <p class="mt-2 font-proximaLight text-sm">
              Please fill in your availability below, using 24-hour time.
            </p>
          </div>
          <div class="w-full">
            <div class="flex justify-between">
              <div class="flex w-6/12">
                <RadioOnoffBorder
                  :val="{ enable: mondayEnable }"
                  :checked="mondayEnable"
                  @inputVal="getMondayCheck"
                />
                <span class="font-proximaMedium text-sm">Monday</span>
              </div>
              <div class="flex w-6/12">
                <RadioOnoffBorder
                  :val="{ name: 'copy all' }"
                  :checked="false"
                  @inputVal="copyAll"
                />
                <span class="font-proximaMedium text-sm">Copy to All</span>
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="w-6/12">
                <span
                  class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
                  >Working Hours</span
                >
                <div class="mt-2 flex">
                  <vue-timepicker
                    append-to-body
                    format="HH:mm"
                    :minute-interval="5"
                    v-model="mondayStart"
                    @change="toCheckMondayHours"
                  ></vue-timepicker>
                  <vue-timepicker
                    append-to-body
                    format="HH:mm"
                    :minute-interval="5"
                    :hour-range="[[mondayStart.HH, 23]]"
                    v-model="mondayEnd"
                    @change="toCheckMondayHours"
                  ></vue-timepicker>
                </div>
              </div>
              <div class="w-6/12">
                <span
                  class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
                  >Break Time</span
                >
                <div class="mt-2 flex">
                  <vue-timepicker
                    append-to-body
                    format="HH:mm"
                    :minute-interval="5"
                    :hour-range="[[mondayStart.HH, mondayEnd.HH]]"
                    v-model="mondayBreakStart"
                    @change="toCheckMondayHours"
                  ></vue-timepicker>
                  <vue-timepicker
                    append-to-body
                    format="HH:mm"
                    :minute-interval="5"
                    :hour-range="[[mondayBreakStart.HH, mondayEnd.HH]]"
                    v-model="mondayBreakEnd"
                    @change="toCheckMondayHours"
                  ></vue-timepicker>
                </div>
              </div>
            </div>
            <div class="w-10/12">
              <FormError :errors="errors.monday" />
            </div>
            <TuesdayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @tuesdayHours="tuesdayHours"
              @getTuesdayCheck="getTuesdayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.tuesday" />
            <WednesdayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @wednesdayHours="wednesdayHours"
              @getWednesdayCheck="getWednesdayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.wednesday" />
            <ThursdayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @thursdayHours="thursdayHours"
              @getThursdayCheck="getThursdayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.thursday" />
            <FridayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @fridayHours="fridayHours"
              @getFridayCheck="getFridayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.friday" />
            <SaturdayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @saturdayHours="saturdayHours"
              @getSaturdayCheck="getSaturdayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.saturday" />
            <SundayVue
              :start="childMondayStart"
              :end="childMondayEnd"
              :breakStart="childMondayBreakStart"
              :breakEnd="childMondayBreakEnd"
              @sundayHours="sundayHours"
              @getSundayCheck="getSundayCheck"
              @removeTime="removeTime"
            />
            <FormError :errors="errors.sunday" />
          </div>
          <div class="mt-10 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeManageContactModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                title="Save"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="toggleSaveBtn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.umd.min.js";
import TuesdayVue from "../openinghourscomponent/Tuesday.vue";
import WednesdayVue from "../openinghourscomponent/Wednesday.vue";
import ThursdayVue from "../openinghourscomponent/Thursday.vue";
import FridayVue from "../openinghourscomponent/Friday.vue";
import SaturdayVue from "../openinghourscomponent/Saturday.vue";
import SundayVue from "../openinghourscomponent/Sunday.vue";
import FormError from "../../warning/FormError.vue";

import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    RadioOnoffBorder,
    SubmitButton,
    BorderedButton,
    VueTimepicker,
    TuesdayVue,
    WednesdayVue,
    ThursdayVue,
    FridayVue,
    SaturdayVue,
    SundayVue,
    FormError,
  },
  data() {
    return {
      mondayStart: {
        HH: "",
        mm: "",
      },
      mondayEnd: {
        HH: "",
        mm: "",
      },
      mondayBreakStart: {
        HH: "",
        mm: "",
      },
      mondayBreakEnd: {
        HH: "",
        mm: "",
      },
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      childMondayStart: {},
      childMondayEnd: {},
      childMondayBreakStart: {},
      childMondayBreakEnd: {},
      addTimee: false,
      mondayEnable: false,
      tuesdayEnable: false,
      wednesdayEnable: false,
      thursdayEnable: false,
      fridayEnable: false,
      saturdayEnable: false,
      sundayEnable: false,
      errors: {},
    };
  },
  computed: {
    ...mapState(["setOpeningHourModalsState"]),
  },
  methods: {
    ...mapActions({
      addWorkingHours: "serviceprovider/addWorkingHours",
    }),

    closeManageContactModals() {
      this.$store.commit("setOpeningHourModals", false);
    },
    copyAll(i, e) {
      console.log(i);
      console.log(e);
      if (e == true) {
        this.addTimee = true;
        const mergedArray = [].concat(
          this.mondayStart,
          this.mondayEnd,
          this.mondayBreakStart,
          this.mondayBreakEnd
        );
        if (this.monday.length != 0) {
          this.monday = mergedArray;
          this.tuesday = mergedArray;
          this.wednesday = mergedArray;
          this.thursday = mergedArray;
          this.friday = mergedArray;
          this.saturday = mergedArray;
          this.sunday = mergedArray;
        }
        this.errors.monday = "Please fill in these fields.";

        this.childMondayStart = this.mondayStart;
        this.childMondayEnd = this.mondayEnd;
        this.childMondayBreakStart = this.mondayBreakStart;
        this.childMondayBreakEnd = this.mondayBreakEnd;
      } else {
        this.childMondayStart = {};
        this.childMondayEnd = {};
        this.childMondayBreakStart = {};
        this.childMondayBreakEnd = {};
      }
    },
    toCheckMondayHours() {
      const mergedArray = [].concat(
        this.mondayStart,
        this.mondayEnd,
        this.mondayBreakStart,
        this.mondayBreakEnd
      );
      this.monday = mergedArray;
    },
    tuesdayHours(e) {
      this.tuesday = e;
    },
    wednesdayHours(e) {
      this.wednesday = e;
    },
    thursdayHours(e) {
      this.thursday = e;
    },
    fridayHours(e) {
      this.friday = e;
    },
    saturdayHours(e) {
      this.saturday = e;
    },
    sundayHours(e) {
      this.sunday = e;
    },

    getMondayCheck(val, check) {
      console.log(val, check);
      this.mondayEnable = check;
    },
    getTuesdayCheck(val, check) {
      console.log(val, check);
      this.tuesdayEnable = check;
    },
    getWednesdayCheck(check) {
      console.log(check);
      this.wednesdayEnable = check;
    },
    getThursdayCheck(val, check) {
      console.log(val, check);
      this.thursdayEnable = check;
    },
    getFridayCheck(val, check) {
      console.log(val, check);
      this.fridayEnable = check;
    },
    getSaturdayCheck(val, check) {
      console.log(val, check);
      this.saturdayEnable = check;
    },
    getSundayCheck(val, check) {
      console.log(val, check);
      this.sundayEnable = check;
    },
    removeTime(day) {
      day == "monday"
        ? (this.monday = [])
        : day == "tuesday"
        ? (this.tuesday = [])
        : day == "wednesday"
        ? (this.wednesday = [])
        : day == "thursday"
        ? (this.thursday = [])
        : day == "friday"
        ? (this.friday = [])
        : day == "saturday"
        ? (this.saturday = [])
        : day == "sunday"
        ? (this.sunday = [])
        : null;
    },

    validate() {
      const errors = {};
      if (this.monday.length != 0) {
        let data = this.monday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.monday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.monday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.monday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.monday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.monday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.monday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.monday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.monday = "incorrect time";
        }
      }

      if (this.wednesday.length != 0) {
        let data = this.wednesday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.wednesday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.wednesday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.wednesday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.wednesday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.wednesday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.wednesday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.wednesday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.wednesday = "incorrect time";
        }
      }
      if (this.tuesday.length != 0) {
        let data = this.tuesday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.tuesday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.tuesday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.tuesday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.tuesday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.tuesday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.tuesday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.tuesday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.tuesday = "incorrect time";
        }
      }
      if (this.thursday.length != 0) {
        let data = this.thursday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.thursday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.thursday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.thursday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.thursday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.thursday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.thursday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.thursday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.thursday = "incorrect time";
        }
      }
      if (this.friday.length != 0) {
        let data = this.friday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.friday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.friday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.friday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.friday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.friday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.friday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.friday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.friday = "incorrect time";
        }
      }
      if (this.saturday.length != 0) {
        let data = this.saturday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.saturday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.saturday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.saturday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.saturday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.saturday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.saturday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.saturday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.saturday = "incorrect time";
        }
      }
      if (this.sunday.length != 0) {
        let data = this.sunday;
        if (data[0].HH != "" && data[0].mm == "") {
          errors.sunday = "incorrect time";
        }
        if (data[0].HH == "" && data[0].mm != "") {
          errors.sunday = "incorrect time";
        }

        if (data[1].HH != "" && data[1].mm == "") {
          errors.sunday = "incorrect time";
        }
        if (data[1].HH == "" && data[1].mm != "") {
          errors.sunday = "incorrect time";
        }

        if (data[2].HH != "" && data[2].mm == "") {
          errors.sunday = "incorrect time";
        }
        if (data[2].HH == "" && data[2].mm != "") {
          errors.sunday = "incorrect time";
        }

        if (data[3].HH != "" && data[3].mm == "") {
          errors.sunday = "incorrect time";
        }
        if (data[3].HH == "" && data[3].mm != "") {
          errors.sunday = "incorrect time";
        }
      }

      return Object.keys(errors).length === 0 ? null : errors;
    },

    toggleSaveBtn() {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      console.log(
        "mondayStart ",
        this.mondayStart,
        "mondayEnd ",
        this.mondayEnd,
        "mondayBreakStart ",
        this.mondayBreakStart,
        "mondayBreakEnd ",
        this.mondayBreakEnd,
        "monday ",
        this.monday,
        "tuesday ",
        this.tuesday,
        "wednesday ",
        this.wednesday,
        "thursday ",
        this.thursday,
        "friday ",
        this.friday,
        "saturday ",
        this.saturday,
        "sunday ",
        this.sunday,
        "childMondayStart ",
        this.childMondayStart,
        "childMondayEnd ",
        this.childMondayEnd,
        "childMondayBreakStart ",
        this.childMondayBreakStart,
        "childMondayBreakEnd ",
        this.childMondayBreakEnd
      );
      if (
        this.monday.length !== 0 ||
        this.tuesday.length !== 0 ||
        this.wednesday.length !== 0 ||
        this.thursday.length !== 0 ||
        this.friday.length !== 0 ||
        this.saturday.length !== 0 ||
        this.sunday.length !== 0
      ) {
        let t = [];
        if (this.monday.length != 0) {
          let data = this.monday;
          data.day = "Monday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.mondayEnable,
          };
          t.push(req);
        }

        if (this.wednesday.length != 0) {
          let data = this.wednesday;
          data.day = "Wednesday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.wednesdayEnable,
          };
          t.push(req);
        }
        if (this.tuesday.length != 0) {
          let data = this.tuesday;
          data.day = "Tuesday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.tuesdayEnable,
          };
          t.push(req);
        }
        if (this.thursday.length != 0) {
          let data = this.thursday;
          data.day = "Thursday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.thursdayEnable,
          };
          t.push(req);
        }
        if (this.friday.length != 0) {
          let data = this.friday;
          data.day = "Friday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.fridayEnable,
          };
          t.push(req);
        }
        if (this.saturday.length != 0) {
          let data = this.saturday;
          data.day = "Saturday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.saturdayEnable,
          };
          t.push(req);
        }
        if (this.sunday.length != 0) {
          let data = this.sunday;
          data.day = "Sunday";
          const req = {
            emailAddress: localStorage.getItem("email"),
            day: data.day,
            working_start: data[0].HH + ":" + data[0].mm,
            working_finish: data[1].HH + ":" + data[1].mm,
            break_start: data[2].HH + ":" + data[2].mm,
            break_finish: data[3].HH + ":" + data[3].mm,
            enable: this.sundayEnable,
          };
          t.push(req);
        }
        this.addWorkingHours(t);
      } else {
        console.log("false");
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>
<style lang="scss">
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.vue__time-picker-dropdown {
  z-index: 9999;
}
.vue__time-picker {
  width: 8em;
}
.vue__time-picker input.display-time {
  width: 100px;
  text-align: center;
  border-radius: 5px;
  border: none;
  background: rgba(156, 163, 175, 0.2);
}
.vue__time-picker .controls {
  right: 18px;
}
.vue__time-picker .controls > * {
  color: #6c6c6c;
}
</style>
