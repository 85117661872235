<template>
  <div class="pb-12">
    <div v-if="petData">
      <div class="mt-4 flex justify-center items-center flex-col">
        <div
          class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-start md:justify-arround items-start"
          v-if="!editable"
        >
          <div v-for="g in petData" :key="g.id">
            <displayImagePets_
              class="mx-1 mt-4 rounded-md"
              :imageData="g.petImage"
            />
            <div class="filler mx-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-10 text-center font-proximaMedium">
      No Data. <br />
    </div>
  </div>
</template>
  
  <script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import InputImage from "../forms/InputImage.vue";
import PetForms from "./PetForms.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import displayImagePets_ from "../forms/displayImagePets_.vue";

import { petGualleryDirectus } from "../../services/petRecordService";

export default {
  components: {
    HeaderGlobal,
    InputImage,
    PetForms,
    SubmitButton,
    BorderedButton,
    displayImagePets_,
  },
  data() {
    return {
      petData: {},
      url: null,
      file: null,
      editable: false,
      titlemenu1: this.$t("account_dropdown_menu_pet_records"),
      titlemenu2: "",
      titlemenu3: "",
      nameOfSpecies: "",
    };
  },

  async mounted() {
    const c = this.$route.params["codepet"];
    if (c) {
      const document = await petGualleryDirectus(c);

      this.petData = document.data;
      console.log("----", document);
    } else {
      console.log("----", c);
    }
  },
  methods: {},
};
</script>
  
  <style></style>
  