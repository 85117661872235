<template>
  <div>
    <img
      src="@/assets/images/hamburger-icon.png"
      alt="hamburger-icon"
      class="h-3 absolute top-0 left-4 cursor-pointer"
      @click="openMenu"
    />
    <div class="relative">
      <ul
        class="w-max bg-white font-proximaMedium shadow-lg -left-16 top-5 absolute"
        v-if="showMenu"
      >
        <li
          class="pl-4 pr-6 py-2 text-sm font-proximaLight hover:bg-primary border-white border-r-4 hover:border-orange hover:text-white cursor-pointer"
          @click="goTo('MedicalHistoryDashboard', pet)"
        >
          Medical History
        </li>
        <li
          class="pl-4 pr-6 py-2 text-sm font-proximaLight hover:bg-primary border-white border-r-4 hover:border-orange hover:text-white cursor-pointer"
          @click="openNoteModals"
        >
          Care Blog Entry
        </li>
      </ul>
    </div>
    <AddNoteModalsVue
      :addNoteModals="addNoteModals"
      @closeNoteModals="closeNoteModals"
      :pet="pet"
      :diary="false"
    />
  </div>
</template>

<script>
import AddNoteModalsVue from "../modals/AddNoteModals.vue";

export default {
  props: ["pet"],
  components: {
    AddNoteModalsVue,
  },
  data() {
    return {
      showMenu: false,
      addNoteModals: false,
    };
  },
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    openNoteModals() {
      this.addNoteModals = true;
    },
    closeNoteModals() {
      this.addNoteModals = false;
    },
    goTo(page, pet) {
      localStorage.setItem("pet", JSON.stringify(pet));

      this.$router.push({ name: page, params: { data: pet } });
    },
  },
};
</script>

<style></style>
