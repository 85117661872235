<template>
  <div
    class="w-10/12 mt-6 bg-lightBlue rounded-lg p-10 font-proximaMedium flex"
  >
    <div class="w-6/12 pr-2">
      <h5 class="pb-4">Appointments</h5>
      <ul class="py-2 bg-white rounded-sm flex text-sm justify-between">
        <li class="w-2/12">Time</li>
        <li class="w-2/12">Date</li>
        <li class="w-4/12">Client</li>
        <li class="w-2/12">Pet</li>
        <li class="w-2/12">Script</li>
      </ul>
      <ul
        v-for="(data, i) in appointments"
        :key="i"
        class="py-2 border-b border-primary hover:bg-primary-20 text-sm font-proximaLight flex justify-between"
        :class="{ 'bg-primary-20': selectedScript === i }"
      >
        <li class="w-2/12 flex justify-center items-center">
          {{ data.time ? data.time.substr(0, 5) : "" }}
        </li>
        <li class="w-2/12 flex justify-center items-center">
          {{ data.date }}
        </li>
        <li class="w-4/12 flex justify-center items-center">
          {{ data.client }}
        </li>
        <!--  != 'to review' -->
        <li
          v-if="data.transcript_file_verified"
          class="w-2/12 flex flex-col justify-center items-center"
        >
          {{ data.pet_name }}
          <!-- approved -->
          <star-rating
            v-if="data.transcript_file_verified"
            :show-rating="false"
            read-only
            :round-start-rating="false"
            :star-size="10"
            :rating="data.rating"
            :border-color="'orange'"
            :border-width="1.5"
            :padding="1"
            inactive-color="#fff"
          ></star-rating>
        </li>
        <li
          v-else
          @click="openRateVisitModals(data.pet_name, data.id_appointment)"
          class="w-2/12 text-orange flex justify-center items-center cursor-pointer"
        >
          {{ data.pet_name }}
        </li>
        <li
          v-if="data.transcript_file_verified == 'n/a'"
          class="w-2/12 flex justify-center items-center"
        >
          {{ data.transcript_file_verified }}
        </li>
        <li
          v-else
          @click="
            openVoiceTranscription(
              i,
              data.transcript_file_verified,
              data.transcript_file
            )
          "
          class="w-2/12 flex justify-center items-center cursor-pointer"
          :class="{
            'text-primary': data.transcript_file_verified == true,
            'text-orange': data.transcript_file_verified == false,
          }"
        >
          {{ data.transcript_file_verified ? "approved" : "to review" }}
        </li>
      </ul>
    </div>
    <div v-if="fileText" class="w-6/12 pl-2">
      <!-- v-if="data.transcript_file" -->
      <h5 class="pb-4">Voice Transcription</h5>
      <p class="my-3 text-base text-left font-proximaLight">
        {{ fileText }}
      </p>
      <!--  <p class="my-3 text-base text-left font-proximaLight">
        {{ transcriptions.conversation }}
      </p>
      <p class="mb-3 text-sm text-left font-proximaLight">
        The diagnosis entered for the visit was:
        <b>{{ transcriptions.diagnosis }}</b>
      </p>
      <p
        v-if="whichScript != 'approved'"
        class="text-sm text-left font-proximaLight"
      >
        This information will be recorded on [pet’s name]’s medical record. It
        will be visible to the pet owner. Once approved, it will also be visible
        to other vets.
      </p> -->
      <SubmitButton
        v-if="whichScript != 'approved'"
        title="Publish"
        width="w-max"
        class="m-auto"
        @click.native="publishTrasncript"
      />
    </div>
    <div v-else class="w-6/12 pl-2">
      <h5 class="pb-4">Voice Transcription</h5>
      <p class="mt-4 text-sm font-proximaLight">
        Please choose appointment's script
      </p>
    </div>
    <RateVisitModalsVue
      :id_appointment="id_appointment_review"
      :pet_name="pet_name_review"
      :rateVisitModals="rateVisitModals"
      @closeRateVisitModals="closeRateVisitModals"
      @submitRateVisitModals="submitRateVisitModals"
    />
    <RatingConfirmationModals
      :ratingConfirmationModals="ratingConfirmation"
      @closeRatingConfirmationModals="closeRatingConfirmationModals"
    />
  </div>
</template>

<script>
import RatingConfirmationModals from "../modals/RatingConfirmationModals.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import RateVisitModalsVue from "../modals/RateVisitModals.vue";

import StarRating from "vue-star-rating";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    ratingConfirmationModals: Boolean,
    appointments: {},
  },
  components: {
    SubmitButton,
    RateVisitModalsVue,
    RatingConfirmationModals,
    StarRating,
  },
  data() {
    return {
      fileText: "", // This is where the fetched content will be stored
      rateVisitModals: false,
      pet_name_review: "",
      id_appointment_review: 0,
      selectedScript: null,
      whichScript: null,
      ratingConfirmation: this.RatingConfirmationModals,
      /*   appointments: [
        {
          time: "9:00",
          date: "08-12-2021",
          client: "Jamie Rieker",
          rating: 4.5,
          pet: "Rocky",
          days: "6",
          script: "approved",
        },
        {
          time: "10:00",
          date: "09-12-2021",
          client: "Susanna Hobs",
          rating: 0,
          pet: "Duke",
          days: "5",
          script: "to review",
        },
        {
          time: "11:00",
          date: "10-12-2021",
          client: "Ismail Achab",
          rating: 0,
          pet: "Bear",
          days: "4",
          script: "n/a",
        },
        {
          time: "12:00",
          date: "11-12-2021",
          client: "Adam J. Christmas",
          rating: 0,
          pet: "Milo",
          days: "3",
          script: "n/a",
        },
      ], */
    };
  },

  methods: {
    async fetchFileContent(url) {
      try {
        const response = await fetch(url);
        if (response.ok) {
          this.fileText = await response.text();
        } else {
          console.error("Failed to fetch file content");
        }
      } catch (error) {
        console.error("Error fetching file content:", error);
      }
    },

    openRateVisitModals(pet_name, id_appointment) {
      console.log(pet_name, "-------", id_appointment);
      this.rateVisitModals = true;
      this.pet_name_review = pet_name;
      this.id_appointment_review = 4; //id_appointment;
    },
    closeRateVisitModals() {
      this.rateVisitModals = false;
      this.pet_name_review = "";
    },
    submitRateVisitModals() {
      this.rateVisitModals = false;
      this.ratingConfirmation = true;
    },
    closeRatingConfirmationModals() {
      this.ratingConfirmation = false;
    },
    openVoiceTranscription(i, e, f) {
      // get from the API
      this.fetchFileContent(f);
      this.selectedScript = i;
      this.whichScript = e;
    },
    publishTrasncript() {
      this.selectedScript = null;
    },
  },
};
</script>

<style></style>
