<template>
  <div class="my-6">
    <PetProfileDirectusSection />
  </div>
</template>
  
  <script>
import PetProfileDirectusSection from "../components/petrecord/PetProfileDirectusSection.vue";

export default {
  components: {
    PetProfileDirectusSection,
  },
};
</script>
  