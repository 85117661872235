<template>
  <Transition name="modal">
    <div v-if="addNoteModals" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 h-screen flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
        >
          <div class="">
            <img
              class="m-auto"
              ref="prefetch"
              src="@/assets/images/diary-bg-icon.png"
              alt="diary-icon"
            />
            <h1 class="mt-4 text-2xl">Add Note to Blog</h1>
            <FormError :errors="errorForm" />
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_title") }}</h5>
            <input
              ref="title"
              class="w-full p-2 border-2 border-primary rounded-md"
              type="text"
              v-model="title"
            />
            <FormError :errors="errors.title" />
          </div>
          <div class="w-full mt-4">
            <h5 class="text-left">{{ $t("global_description") }}</h5>
            <textarea
              class="w-full p-2 border-2 border-primary rounded-md"
              cols="30"
              rows="5"
              v-model="description"
            ></textarea>
            <FormError :errors="errors.description" />
          </div>
          <div class="mt-4 w-full flex items-center justify-start">
            <EmojiEach
              v-for="(emoji, i) in emojis"
              :key="i + 1"
              :eachEmoji="i + 1"
              :highlightedEmoji="highlightedEmoji"
              :whichEmoji="emoji.name"
              @click.native="clickIcon(emoji)"
            />
          </div>
          <div class="w-full mt-3">
            <NewTag
              :tags="this.tags"
              @changeTag="changeTag"
              @deleteTag="deleteTag"
            />
            <!-- <input class="w-full p-2 border-2 border-primary rounded-md" type="text" placeholder="Enter keyword tags, separated by a comma" v-model="tags" /> -->
          </div>
          <div class="mt-12 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeDiaryModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="submitAddCareBlog"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import NewTag from "./NewTag.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import EmojiEach from "../../emojis/EmojiEach.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    addNoteModals: Boolean,
    diary: Boolean,
    pet: {},
  },
  components: {
    SubmitButton,
    BorderedButton,
    TrashIcon,
    EmojiEach,
    FormError,
    NewTag,
    ModalsConfirm,
  },
  data() {
    return {
      highlightedEmoji: 1,
      selectedEmoji: "",
      title: "",
      description: "",
      tags: [],
      emojis: [
        {
          id: "1",
          name: "EmojiHeartEyes",
        },
        {
          id: "2",
          name: "EmojiLaugh",
        },
        {
          id: "3",
          name: "EmojiSmile",
        },
        {
          id: "4",
          name: "EmojiSadSlight",
        },
        {
          id: "5",
          name: "EmojiMeh",
        },
        {
          id: "6",
          name: "EmojiSad",
        },
        {
          id: "7",
          name: "EmojiAngry",
        },
      ],
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
      getError: "appointment/getError",
    }),

    pets() {
      return this.petRecords
        ? this.petRecords.filter((item) => item.type == 3)
        : [];
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  watch: {},
  created() {
    this.$store.commit("errorForm", "");
    this.getPetRecords(localStorage.getItem("email"));
  },
  methods: {
    ...mapActions({
      addCareBlog: "appointment/addCareBlog",
      getPetRecords: "petRecord/getPetRecords",
    }),
    clickIcon(emoji, i) {
      console.log(emoji.name);
      this.highlightedEmoji = emoji.id;
      this.selectedEmoji = emoji.name;
    },
    closeDiaryModals() {
      this.$emit("closeNoteModals", false);
      this.emptyField();
    },

    emptyField() {
      this.title = "";
      this.description = "";
      this.highlightedEmoji = 1;
      this.tags = [];
      this.errors = {};
    },

    changeTag(val) {
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.tags.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.tags.push(line);
        }
      });
      console.log(this.tags);
    },
    deleteTag(val) {
      const diags = this.tags.filter((c) => c !== val);
      this.tags = diags;
    },
    validate() {
      const errors = {};

      if (this.title.trim() === "")
        errors.title =
          this.$t("global_title") + " " + this.$t("global_is_required");

      if (this.description.trim() === "")
        errors.description =
          this.$t("global_description") + " " + this.$t("global_is_required");

      if (this.date_appointment === "")
        errors.date_appointment =
          this.$t("global_date") + " " + this.$t("global_is_required");

      if (this.time_appointment === "")
        errors.time_appointment =
          this.$t("global_time") + " " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    submitAddCareBlog() {
      const data = {
        userEmailAddress: localStorage.getItem("email"),
        pets: [this.pet.petID],
        title: this.title,
        description: this.description,
        mood: this.highlightedEmoji,
        diary_tags: this.tags,
        diary: this.diary,
      };

      console.log(data);

      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      this.addCareBlog(data);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
