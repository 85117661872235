<template>
  <div class="flex flex-col justify-center items-center">
    <HeaderGlobal />
    <div
      class="mb-6 w-max bg-primary rounded-full font-proximaMedium text-white py-3 px-8"
    >
      PerPETual Dashboard
    </div>
    <ListOfPets :pets="petRecords" />
    <AppointmentsVoiceTranscription :appointments="appointments" />
    <RatingReminderModals
      :ratingReminderModals="ratingReminderModals"
      @closeRatingReminderModals="closeRatingModals"
    />
    <ManageContactModals />
    <ManageServiceModals />
    <SetOpeningHourModals />
    <ManageProfileImageModals
      @uploadImageModals="uploadImageModals"
      :imgUrl="imgUrl"
      :imgFile="imgFile"
      :whichImage="whichImage"
    />

    <!-- choose files modals -->
    <ModalsConfirm :show="toggleUploadImageModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="toggleUploadImageModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/media-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Choose a File</h1>
        <p class="mt-4 font-proximaLight text-sm text-subheaderText">
          Medical files are supported, in addition to image and PDF format.
          <br /><br />
          You may take a new photo or upload an existing file from your device
          (max 35MB).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton
          component="PaperclipIcon"
          iconColor="#fff"
          title="Upload File"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="mr-2 py-4"
          @click.native="openFiles()"
        />
        <IconButton
          component="CameraIcon"
          iconColor="#fff"
          title="Take Photo"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="ml-2"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- choose files modals end -->

    <!-- preview files modals -->
    <ModalsConfirm :show="previewFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-10 text-lg font-proximaSemibold cursor-pointer"
          @click="previewFileModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <img :src="imgUrl" :alt="imgUrl" />
        <h1 class="my-4 text-2xl">Attach Photo?</h1>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Retake"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openFiles()"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openManageProfileImageModals"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- preview files modals end -->
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AppointmentsVoiceTranscription from "./sections/AppointmentsVoiceTranscription.vue";
import ListOfPets from "./sections/ListOfPets.vue";
import RatingReminderModals from "./modals/RatingReminderModals.vue";
import ManageContactModals from "./modals/ManageContactModals.vue";
import ManageServiceModals from "./modals/ManageServiceModals.vue";
import SetOpeningHourModals from "./modals/SetOpeningHourModals.vue";
import ManageProfileImageModals from "./modals/ManageProfileImageModals.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import IconButton from "../buttons/IconButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    ListOfPets,
    AppointmentsVoiceTranscription,
    RatingReminderModals,
    ManageContactModals,
    ManageServiceModals,
    SetOpeningHourModals,
    ManageProfileImageModals,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    IconButton,
  },
  data() {
    return {
      ratingReminderModals: true,
      toggleUploadImageModals: false,
      previewFileModals: false,
      uploadedFileName: "",
      imgUrl: "",
      imgFile: null,
      whichImage: "",
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
      appointments: "appointment/getAppointments",
    }),
  },
  async mounted() {
    let datato = {};
    this.$store.commit("showLoader", true);
    if (!localStorage.getItem("email")) {
      console.log("**********");
      if (this.$route.params["code7"] && this.$route.params["code8"]) {
        console.log("-------- google ----------");
        const urlSocial =
          this.$route.params["code7"] +
          "/" +
          this.$route.params["code8"] +
          "/" +
          window.location.search;

        await getEmailSocialRegistration(urlSocial)
          .then((result) => {
            this.signInAfterLogin(result.data).then(() => {
              this.getPetRecords(localStorage.getItem("email"));
            });
          })
          .catch((error) => {
            if (error.response && error.response.status == 404) {
              this.$router.push("/");
              console.log("User Not registered");
            }
            if (error.response && error.response.status == 401) {
              console.log("Email address registered using anothe provider");
            }
            if (error.response && error.response.status == 403) {
              console.log("INACTIVE ACCOUNT");
            }
          });
      } else if (this.$route.params["::code11"] != "news") {
        console.log("---------- magic link -----------------");
        let code = this.$route.params["::code11"];
        this.signIn(code);
        console.log("---------- magic link 1 -----------------");
        this.getPetRecords(localStorage.getItem("email"));
        console.log("---------- magic link 2 -----------------");
      }
    } else {
      console.log("------------------");
      this.getPetRecords(localStorage.getItem("email"));
    }
    const firstpet = this.petRecords[0];
    localStorage.setItem("firstpet", JSON.stringify(firstpet));
    this.getAppointmentOfpassiveSv();
    /* else if(this.$route.params["::code11"] == "news" &&
      localStorage.getItem("email")){

    } */
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
      getPetRecords: "petRecord/getPetRecords",
      getAppointmentOfpassiveSv: "appointment/getAppointmentOfpassiveSv",
    }),

    closeRatingModals() {
      this.ratingReminderModals = false;
    },
    uploadImageModals(i) {
      this.toggleUploadImageModals = true;
      this.whichImage = i;
      console.log({ i });
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.value;
      let file = e.target.files[0];
      this.imgUrl = URL.createObjectURL(file);
      this.imgFile = file;

      this.toggleUploadImageModals = false;
      this.previewFileModals = true;
    },
    openManageProfileImageModals() {
      this.previewFileModals = false;
      this.$store.commit("manageProfileImagesModals", true);
    },
  },
};
</script>

<style></style>
