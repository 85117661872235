<template>
  <Transition name="modal">
    <div v-if="manageProfileImagesModalsState" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium text-subheaderText overflow-y-auto relative"
        >
          <div
            class="absolute top-3 right-6 text-3xl font-proximaSemibold cursor-pointer"
            @click="closeProfileImageModals"
          >
            ✖
          </div>
          <div class="w-10/12 mb-6">
            <img
              class="h-28 m-auto"
              src="@/assets/images/media-icon.png"
              alt="media-icon"
            />
            <h1 class="text-2xl">Manage Profile Images</h1>
            <FormError :errors="errorForm" />
            <p class="mt-2 font-proximaLight text-sm text-left">
              You may want to display images of your service, location,
              certifications etc. You can take a new photo or upload an existing
              image from your device. The images are displayed in the order
              given below. The first image is the primary image.
            </p>
          </div>
          <div class="w-10/12 flex flex-wrap justify-between">
            <div class="w-3/12 mx-4">
              <div
                v-if="images[0]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-show="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(0)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[0])"
                      :primary="images[0]"
                      :key="images[0].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[0].serviceImage"
                  :alt="images[0].serviceImage"
                  @error="imageUrlAlt(images[0])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(0)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    1st
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4">
              <div
                v-if="images[1]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(1)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[1])"
                      :primary="images[1]"
                      :key="images[1].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[1].serviceImage"
                  :alt="images[1].serviceImage"
                  @error="imageUrlAlt(images[1])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(1)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    2nd
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4">
              <div
                v-if="images[2]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(2)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[2])"
                      :primary="images[2]"
                      :key="images[2].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[2].serviceImage"
                  :alt="images[2].serviceImage"
                  @error="imageUrlAlt(images[2])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(2)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    3rd
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4 my-4">
              <div
                v-if="images[3]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(3)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[3])"
                      :primary="images[3]"
                      :key="images[3].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[3].serviceImage"
                  :alt="images[3].serviceImage"
                  @error="imageUrlAlt(images[3])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(3)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    4th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4 my-4">
              <div
                v-if="images[4]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(4)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[4])"
                      :primary="images[4]"
                      :key="images[4].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[4].serviceImage"
                  :alt="images[4].serviceImage"
                  @error="imageUrlAlt(images[4])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(4)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    5th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4 my-4">
              <div
                v-if="images[5]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(5)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[5])"
                      :primary="images[5]"
                      :key="images[5].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[5].serviceImage"
                  :alt="images[5].serviceImage"
                  @error="imageUrlAlt(images[5])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(5)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    6th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4">
              <div
                v-if="images[6]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(6)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[6])"
                      :primary="images[6]"
                      :key="images[6].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[6].serviceImage"
                  :alt="images[6].serviceImage"
                  @error="imageUrlAlt(images[6])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(6)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    7th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4">
              <div
                v-if="images[7]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(7)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[7])"
                      :primary="images[7]"
                      :key="images[7].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[7].serviceImage"
                  :alt="images[7].serviceImage"
                  @error="imageUrlAlt(images[7])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(7)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    8th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
            <div class="w-3/12 mx-4">
              <div
                v-if="images[8]"
                class="relative"
                @mouseenter="toggleHoverImage = true"
                @mouseleave="toggleHoverImage = false"
              >
                <div
                  v-if="toggleHoverImage"
                  class="w-full h-full bg-lightGrey20 absolute flex flex-col items-center justify-center"
                >
                  <TrashIcon
                    iconColor="#0AC0CB"
                    bgColor="#fff"
                    :height="32"
                    :width="32"
                    class="cursor-pointer"
                    @click.native="removeImage(8)"
                  />
                  <div>
                    <StarComponent
                      @toggle-primary="togglePrimaryImage(images[8])"
                      :primary="images[8]"
                      :key="images[8].id"
                    />
                  </div>
                </div>
                <img
                  :src="images[8].serviceImage"
                  :alt="images[8].serviceImage"
                  @error="imageUrlAlt(images[8])"
                />
              </div>
              <div
                v-else
                class="px-10 pb-6 border-2 border-primary rounded-md cursor-pointer"
                @click="openUploadImageModals(8)"
              >
                <div class="flex flex-col items-center justify-center">
                  <h5 class="mb-3 mt-2 text-sm text-primary font-proximaLight">
                    9th
                  </h5>
                  <img
                    class="h-10"
                    src="@/assets/images/galery-icon.png"
                    alt="galery-icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-10 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                title="Confirm"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="bSubmit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapState } from "vuex";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import FormError from "../../warning/FormError.vue";
import TrashIcon from "../../icons/TrashIcon.vue";
import StarComponent from "../starcomponent/StarComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["whichImage", "imgUrl", "imgFile"],
  components: {
    SubmitButton,
    BorderedButton,
    TrashIcon,
    StarComponent,
    FormError,
  },
  data() {
    return {
      toggleHoverImage: false,
      primaryImg: "",
      /* images: [
        { id: 1, serviceImage: "", imageurl: null, primary: false },
        { id: 2, serviceImage: "", imageurl: null, primary: false },
        { id: 3, serviceImage: "", imageurl: null, primary: false },
        { id: 4, serviceImage: "", imageurl: null, primary: false },
        { id: 5, serviceImage: "", imageurl: null, primary: false },
        { id: 6, serviceImage: "", imageurl: null, primary: false },
        { id: 7, serviceImage: "", imageurl: null, primary: false },
        { id: 8, serviceImage: "", imageurl: null, primary: false },
        { id: 9, serviceImage: "", imageurl: null, primary: false },
      ], */
    };
  },
  computed: {
    ...mapState(["manageProfileImagesModalsState"]),
    ...mapGetters({
      images: "serviceprovider/getSpgallery",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  watch: {
    imgUrl(i) {
      const a = {
        serviceImage: this.imgUrl,
        imageurl: this.imgFile,
        type: 1, //new
      };

      this.addSpgalleryComp(a);
    },
  },
  async created() {
    this.getSpgallery();
    this.$store.commit("errorForm", "");
    /* this.images[0].serviceImage = "";
    this.images[1].serviceImage = "";
    this.images[2].serviceImage = "";
    this.images[3].serviceImage = "";
    this.images[4].serviceImage = "";
    this.images[5].serviceImage = "";
    this.images[6].serviceImage = "";
    this.images[7].serviceImage = "";
    this.images[8].serviceImage = ""; */
  },
  methods: {
    ...mapActions({
      addSpgallery: "serviceprovider/addSpgallery",
      getSpgallery: "serviceprovider/getSpgallery",
      deleteSPGallery: "serviceprovider/deleteSPGallery",
      addSpgalleryComp: "serviceprovider/addSpgalleryComp",
      updateSpgalleryComp: "serviceprovider/updateSpgalleryComp",
    }),
    closeProfileImageModals() {
      this.$store.commit("manageProfileImagesModals", false);
      this.$store.commit("errorForm", "");
      /* this.images[0].serviceImage = "";
      this.images[1].serviceImage = "";
      this.images[2].serviceImage = "";
      this.images[3].serviceImage = "";
      this.images[4].serviceImage = "";
      this.images[5].serviceImage = "";
      this.images[6].serviceImage = "";
      this.images[7].serviceImage = "";
      this.images[8].serviceImage = ""; */
    },
    openUploadImageModals(i) {
      this.$emit("uploadImageModals", i);
      this.$store.commit("manageProfileImagesModals", false);
      //   this.$store.commit("manageContactDetailsModals", false);
    },
    removeImage(i) {
      this.images[i].serviceImage = "";
      let a = this.images[i];
      a.type = 0; //delete
      this.updateSpgalleryComp(a);

      //this.images[i].serviceImage = "";
    },
    togglePrimaryImage(clickedImage) {
      this.images = this.images.map((img) => {
        if (img.id === clickedImage.id) {
          return { ...img, primary: true };
        } else {
          imageurl;
          return { ...img, primary: false };
        }
      });
    },
    bSubmit() {
      this.images.map((item) => {
        if (item.serviceImage != "") {
          if (item.type == 1) {
            const data = {
              emailAddress: localStorage.getItem("email"),
              serviceImage: item.imageurl,
            };

            this.addSpgallery(data);
          }
        } else if (item.type == 0) {
          this.deleteSPGallery(item.id);
        }
      });
    },
    imageUrlAlt(img) {
      console.log("nnnnnnnnnn", imd);
      this.deleteSPGallery(img.id);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
