<template>
  <div class="my-6">
    <PetDocDirectusSection />
  </div>
</template>
  
  <script>
import PetDocDirectusSection from "../components/petrecord/PetDocDirectusSection.vue";

export default {
  components: {
    PetDocDirectusSection,
  },
};
</script>
  