var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-10/12 mt-6 bg-lightBlue rounded-lg p-10 font-proximaMedium flex"},[_c('div',{staticClass:"w-6/12 pr-2"},[_c('h5',{staticClass:"pb-4"},[_vm._v("Appointments")]),_vm._m(0),_vm._l((_vm.appointments),function(data,i){return _c('ul',{key:i,staticClass:"py-2 border-b border-primary hover:bg-primary-20 text-sm font-proximaLight flex justify-between",class:{ 'bg-primary-20': _vm.selectedScript === i }},[_c('li',{staticClass:"w-2/12 flex justify-center items-center"},[_vm._v(" "+_vm._s(data.time ? data.time.substr(0, 5) : "")+" ")]),_c('li',{staticClass:"w-2/12 flex justify-center items-center"},[_vm._v(" "+_vm._s(data.date)+" ")]),_c('li',{staticClass:"w-4/12 flex justify-center items-center"},[_vm._v(" "+_vm._s(data.client)+" ")]),(data.transcript_file_verified)?_c('li',{staticClass:"w-2/12 flex flex-col justify-center items-center"},[_vm._v(" "+_vm._s(data.pet_name)+" "),(data.transcript_file_verified)?_c('star-rating',{attrs:{"show-rating":false,"read-only":"","round-start-rating":false,"star-size":10,"rating":data.rating,"border-color":'orange',"border-width":1.5,"padding":1,"inactive-color":"#fff"}}):_vm._e()],1):_c('li',{staticClass:"w-2/12 text-orange flex justify-center items-center cursor-pointer",on:{"click":function($event){return _vm.openRateVisitModals(data.pet_name, data.id_appointment)}}},[_vm._v(" "+_vm._s(data.pet_name)+" ")]),(data.transcript_file_verified == 'n/a')?_c('li',{staticClass:"w-2/12 flex justify-center items-center"},[_vm._v(" "+_vm._s(data.transcript_file_verified)+" ")]):_c('li',{staticClass:"w-2/12 flex justify-center items-center cursor-pointer",class:{
          'text-primary': data.transcript_file_verified == true,
          'text-orange': data.transcript_file_verified == false,
        },on:{"click":function($event){return _vm.openVoiceTranscription(
            i,
            data.transcript_file_verified,
            data.transcript_file
          )}}},[_vm._v(" "+_vm._s(data.transcript_file_verified ? "approved" : "to review")+" ")])])})],2),(_vm.fileText)?_c('div',{staticClass:"w-6/12 pl-2"},[_c('h5',{staticClass:"pb-4"},[_vm._v("Voice Transcription")]),_c('p',{staticClass:"my-3 text-base text-left font-proximaLight"},[_vm._v(" "+_vm._s(_vm.fileText)+" ")]),(_vm.whichScript != 'approved')?_c('SubmitButton',{staticClass:"m-auto",attrs:{"title":"Publish","width":"w-max"},nativeOn:{"click":function($event){return _vm.publishTrasncript.apply(null, arguments)}}}):_vm._e()],1):_c('div',{staticClass:"w-6/12 pl-2"},[_c('h5',{staticClass:"pb-4"},[_vm._v("Voice Transcription")]),_c('p',{staticClass:"mt-4 text-sm font-proximaLight"},[_vm._v(" Please choose appointment's script ")])]),_c('RateVisitModalsVue',{attrs:{"id_appointment":_vm.id_appointment_review,"pet_name":_vm.pet_name_review,"rateVisitModals":_vm.rateVisitModals},on:{"closeRateVisitModals":_vm.closeRateVisitModals,"submitRateVisitModals":_vm.submitRateVisitModals}}),_c('RatingConfirmationModals',{attrs:{"ratingConfirmationModals":_vm.ratingConfirmation},on:{"closeRatingConfirmationModals":_vm.closeRatingConfirmationModals}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"py-2 bg-white rounded-sm flex text-sm justify-between"},[_c('li',{staticClass:"w-2/12"},[_vm._v("Time")]),_c('li',{staticClass:"w-2/12"},[_vm._v("Date")]),_c('li',{staticClass:"w-4/12"},[_vm._v("Client")]),_c('li',{staticClass:"w-2/12"},[_vm._v("Pet")]),_c('li',{staticClass:"w-2/12"},[_vm._v("Script")])])
}]

export { render, staticRenderFns }