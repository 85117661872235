<template>
  <div
    @click="togglePrimary"
    class="mt-1 py-1 px-2 bg-white rounded-sm flex items-center cursor-pointer font-proximaLight text-sm"
  >
    <img
      v-if="primary.primary"
      src="@/assets/images/filled-star-icon.png"
      alt="filled-star-icon"
    />
    <img
      v-else
      src="@/assets/images/nofill-star-icon.png"
      alt="nofill-star-icon"
    />
    <span class="ml-1">primary</span>
  </div>
</template>

<script>
export default {
  props: ["primary"],
  data() {
    return {
      rating: false,
    };
  },
  methods: {
    togglePrimary() {
      this.$emit('toggle-primary');
    }
  },
};
</script>

<style>
</style>