<template>
  <div
    v-if="imageData"
    class="image-input"
    :style="{ 'background-image': `url(${imageData})` }"
  >
    <div
      v-if="!imageData"
      class="placeholder rounded-md flex flex-col items-center justify-center transition-all font-proximaLight text-primary"
    >
      <img src="@/assets/images/galery-icon.png" alt="" class="h-14 mb-6" />
    </div>
  </div>
  <div v-else class="image-input">
    <div
      v-if="!imageData"
      class="placeholder rounded-md flex flex-col items-center justify-center transition-all font-proximaLight text-primary"
    >
      <!-- <img src="@/assets/images/galery-icon.png" alt="" class="h-14 mb-6" /> -->
    </div>
  </div>
</template>
  
<script>
export default {
  props: { imageData: null },
  data() {
    return {};
  },
};
</script>
  
<style scoped>
.image-input {
  display: block;
  width: 280px;
  height: 280px;

  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder:hover {
  background: #fafafa;
}

.file-input {
  display: none;
}
</style>
  