<template>
  <div class="mt-5">
    <div class="flex items-center">
      <div class="flex w-6/12">
        <RadioOnoffBorder
          :val="{ name: 'thursday' }"
          :checked="false"
          @inputVal="thursdayVal"
        />
        <span class="font-proximaMedium text-sm">Thursday</span>
      </div>
      <div class="w-5/12 flex justify-end">
        <AddIconVue
          class="cursor-pointer"
          :width="24"
          :height="24"
          @click.native="addTime = true"
          v-if="!addTime"
        />
      </div>
    </div>
    <div v-if="addTime" class="flex justify-between items-center">
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Working Hours</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            v-model="thursdayStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[thursdayStart.HH, 23]]"
            v-model="thursdayEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Break Time</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[thursdayStart.HH, thursdayEnd.HH]]"
            v-model="thursdayBreakStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[thursdayBreakStart.HH, thursdayEnd.HH]]"
            v-model="thursdayBreakEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <trash-icon-vue
        bgColor="#0AC0CB"
        iconColor="#fff"
        :width="24"
        :height="24"
        class="mt-9 cursor-pointer"
        @click.native="removeTime"
        v-if="addTime"
      />
    </div>
  </div>
</template>

<script>
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import TrashIconVue from "../../icons/TrashIcon.vue";
import AddIconVue from "../../icons/AddIcon.vue";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.umd.min.js";

import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: ["start", "end", "breakStart", "breakEnd"],
  components: {
    RadioOnoffBorder,
    AddIconVue,
    VueTimepicker,
    TrashIconVue,
  },
  data() {
    return {
      thursdayStart: {
        HH: "",
        mm: "",
      },
      thursdayEnd: {
        HH: "",
        mm: "",
      },
      thursdayBreakStart: {
        HH: "",
        mm: "",
      },
      thursdayBreakEnd: {
        HH: "",
        mm: "",
      },
      addTime: false,
    };
  },
  watch: {
    breakEnd(i) {
      if (
        this.start.mm == 0 &&
        this.end.mm == 0 &&
        this.breakStart.mm == 0 &&
        i.mm == 0
      ) {
        this.addFromParentMethod();
      } else {
        this.removeFromParentMethod();
      }
    },
  },
  methods: {
    removeTime() {
      addTime = false;
      this.$emit("removeTime", "thursday");
    },
    changeHandler(eventData) {
      const mergedArray = [].concat(
        this.thursdayStart,
        this.thursdayEnd,
        this.thursdayBreakStart,
        this.thursdayBreakEnd
      );
      this.$emit("thursdayHours", mergedArray);
    },
    thursdayVal(i, e) {
      this.$emit("getThursdayCheck", e);
    },
    addFromParentMethod() {
      this.addTime = true;
      this.thursdayStart = this.start;
      this.thursdayEnd = this.end;
      this.thursdayBreakStart = this.breakStart;
      this.thursdayBreakEnd = this.breakEnd;
    },
    removeFromParentMethod() {
      this.addTime = false;
      this.thursdayStart = {};
      this.thursdayEnd = {};
      this.thursdayBreakStart = {};
      this.thursdayBreakEnd = {};
    },
  },
};
</script>

<style></style>
