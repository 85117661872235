<template>
  <Transition name="modal">
    <div v-if="rateVisitModals" class="modal-mask">
      <div class="modal-wrapper text-subheaderText">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center font-proximaMedium overflow-y-auto"
        >
          <div class="">
            <h1 class="mt-4 text-2xl">Rate {{ pet_name }}'s Visit</h1>
          </div>
          <div class="w-8/12 mt-4 font-proximaMedium text-sm">
            <div class="py-1 flex justify-between">
              <span>Manners</span>
              <star-rating
                v-model="form.manners"
                :show-rating="false"
                :star-size="15"
                :border-color="'orange'"
                :border-width="2"
                :padding="5"
                inactive-color="#fff"
              ></star-rating>
            </div>
            <div class="py-1 flex justify-between">
              <span>Friendliness</span>
              <star-rating
                v-model="form.friendliness"
                :show-rating="false"
                :star-size="15"
                :border-color="'orange'"
                :border-width="2"
                :padding="5"
                inactive-color="#fff"
              ></star-rating>
            </div>
            <div class="py-1 flex justify-between">
              <span>Energy</span>
              <star-rating
                v-model="form.energy"
                :show-rating="false"
                :star-size="15"
                :border-color="'orange'"
                :border-width="2"
                :padding="5"
                inactive-color="#fff"
              ></star-rating>
            </div>
            <div class="py-1 flex justify-between">
              <span>Compliance</span>
              <star-rating
                v-model="form.compliance"
                :show-rating="false"
                :star-size="15"
                :border-color="'orange'"
                :border-width="2"
                :padding="5"
                inactive-color="#fff"
              ></star-rating>
            </div>
            <div class="py-1 flex justify-between">
              <span>Punctuality</span>
              <star-rating
                v-model="form.punctuality"
                :show-rating="false"
                :star-size="15"
                :border-color="'orange'"
                :border-width="2"
                :padding="5"
                inactive-color="#fff"
              ></star-rating>
            </div>
            <InputRadio
              class="mt-6"
              title="Were there any issues with payment?"
              opt1="No"
              opt2="Yes"
              @input="inputPaymentType"
            />

            <InputRadio
              class="mt-2"
              title="Were there any issues with attendance?"
              opt1="No"
              opt2="Yes"
              @input="inputAttendanceType"
            />
          </div>
          <div class="mt-6 w-full md:w-8/12 flex justify-evenly">
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <BorderedButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_cancel')"
                paddingX="px-6"
                width="w-full"
                @click.native="closeRateVisitModals"
              />
            </div>
            <div class="mx-auto w-10/12 md:w-5/12 relative">
              <SubmitButton
                class="mx-auto"
                marginT="mt-2"
                :title="$t('global_confirm')"
                background="bg-success"
                paddingX="px-6"
                width="w-full"
                @click.native="submitRateVisitModals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import StarRating from "vue-star-rating";
import InputRadio from "../../forms/InputRadio.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    rateVisitModals: Boolean,
    pet_name: String,
    id_appointment: Number,
  },
  components: {
    SubmitButton,
    BorderedButton,
    StarRating,
    InputRadio,
  },
  data() {
    return {
      form: {
        appointment: "",
        manners: 0,
        friendliness: 0,
        energy: 0,
        compliance: 0,
        punctuality: 0,
        payment_issue: "NO",
        attendence_issue: "NO",
      },
    };
  },
  methods: {
    ...mapActions({
      appointmentRating: "appointment/appointmentRating",
    }),

    closeRateVisitModals() {
      this.$emit("closeRateVisitModals", false);
    },

    inputPaymentType(val) {
      this.form.payment_issue = val == 2 ? "YES" : "NO";
    },

    inputAttendanceType(val) {
      this.form.attendence_issue = val == 2 ? "YES" : "NO";
    },

    submitRateVisitModals() {
      console.log(
        "manners : ",
        this.form.manners,
        "friendliness : ",
        this.form.friendliness,
        "energy : ",
        this.form.energy,
        "compliance : ",
        this.form.compliance,
        "punctuality : ",
        this.form.punctuality
      );

      this.form.appointment = this.id_appointment;

      console.log(this.form);

      this.appointmentRating(this.form);
      this.$emit("submitRateVisitModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
