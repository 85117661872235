<template>
    <div class="mt-4 w-11/12 md:w-8/12 text-left font-proximaMedium text-sm">
      <h5>Notes:</h5>
      <input
        @input="changeTag"
        placeholder="Enter keyword tags, separated by a comma"
        class="w-full p-2 border-2 border-primary rounded-md"
      />
      <div class="mt-4 flex justify-center">
        <ul class="w-11/12 md:w-6/12 flex justify-start flex-wrap">
          <li
            v-for="(data, index) in tags"
            :key="index"
            class="
              w-max
              mr-2
              mt-2
              text-white
              bg-primary
              rounded-md
              px-3
              py-2
              relative
            "
          >
            {{ data }}
            <div
              class="
                bg-white
                rounded-full
                absolute
                -right-1
                -top-1
                cursor-pointer
              "
            >
              <TrashIcon iconColor="#0AC0CB" @click.native="deleteTag(data)" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import TrashIcon from "@/components/icons/TrashIcon.vue";
  
  export default {
    props: {
      tags: [],
    },
    components: {
      TrashIcon,
    },
    data() {
      return {
      };
    },
    methods: {
      changeTag(e) {
        this.$emit("changeTag", e.target.value);
      },
      deleteTag(val) {
        this.$emit("deleteTag", val);
      },
    },
  };
  </script>
  
  <style></style>
  