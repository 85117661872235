<template>
  <div class="mt-5">
    <div class="flex items-center">
      <div class="flex w-6/12">
        <RadioOnoffBorder
          :val="{ name: 'wednesday' }"
          :checked="false"
          @inputVal="wednesdayVal"
        />
        <span class="font-proximaMedium text-sm">Wednesday</span>
      </div>
      <div class="w-5/12 flex justify-end">
        <AddIconVue
          class="cursor-pointer"
          :width="24"
          :height="24"
          @click.native="addTime = true"
          v-if="!addTime"
        />
      </div>
    </div>
    <div v-if="addTime" class="flex justify-between items-center">
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Working Hours</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            v-model="wednesdayStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[wednesdayStart.HH, 23]]"
            v-model="wednesdayEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <div class="w-6/12">
        <span
          class="w-full ml-4 mt-2 block font-proximaMedium text-sm text-left text-subheaderText"
          >Break Time</span
        >
        <div class="mt-2 flex">
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[wednesdayStart.HH, wednesdayEnd.HH]]"
            v-model="wednesdayBreakStart"
          ></vue-timepicker>
          <vue-timepicker
            append-to-body
            format="HH:mm"
            :minute-interval="5"
            :hour-range="[[wednesdayBreakStart.HH, wednesdayEnd.HH]]"
            v-model="wednesdayBreakEnd"
            @change="changeHandler"
          ></vue-timepicker>
        </div>
      </div>
      <trash-icon-vue
        bgColor="#0AC0CB"
        iconColor="#fff"
        :width="24"
        :height="24"
        class="mt-9 cursor-pointer"
        @click.native="removeTime"
        v-if="addTime"
      />
    </div>
  </div>
</template>

<script>
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import TrashIconVue from "../../icons/TrashIcon.vue";
import AddIconVue from "../../icons/AddIcon.vue";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.umd.min.js";

import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: ["start", "end", "breakStart", "breakEnd"],
  components: {
    RadioOnoffBorder,
    AddIconVue,
    VueTimepicker,
    TrashIconVue,
  },
  data() {
    return {
      wednesdayStart: {
        HH: "",
        mm: "",
      },
      wednesdayEnd: {
        HH: "",
        mm: "",
      },
      wednesdayBreakStart: {
        HH: "",
        mm: "",
      },
      wednesdayBreakEnd: {
        HH: "",
        mm: "",
      },
      addTime: false,
    };
  },
  watch: {
    breakEnd(i) {
      console.log(i.mm);
      if (
        this.start.mm == 0 &&
        this.end.mm == 0 &&
        this.breakStart.mm == 0 &&
        i.mm == 0
      ) {
        this.addFromParentMethod();
      } else {
        this.removeFromParentMethod();
      }
    },
  },
  methods: {
    removeTime() {
      addTime = false;
      this.$emit("removeTime", "wednesday");
    },
    changeHandler(eventData) {
      const mergedArray = [].concat(
        this.wednesdayStart,
        this.wednesdayEnd,
        this.wednesdayBreakStart,
        this.wednesdayBreakEnd
      );
      this.$emit("wednesdayHours", mergedArray);
    },
    wednesdayVal(i, e) {
      this.$emit("getWednesdayCheck", e);
    },
    addFromParentMethod() {
      this.addTime = true;
      this.wednesdayStart = this.start;
      this.wednesdayEnd = this.end;
      this.wednesdayBreakStart = this.breakStart;
      this.wednesdayBreakEnd = this.breakEnd;
    },
    removeFromParentMethod() {
      this.addTime = false;
      this.wednesdayStart = {};
      this.wednesdayEnd = {};
      this.wednesdayBreakStart = {};
      this.wednesdayBreakEnd = {};
    },
  },
};
</script>

<style></style>
